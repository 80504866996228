$(document).ready(function() {

// RESPONSIVE FUNCTIONALITY

    new MobileDeviceRecognition().initialize();

    // function that makes a multiple column layout do a harmonica toggle on mobile phones
    mobileColumnToggle(1);
    mobileColumnToggle(2);
    mobileColumnToggle(3);
    mobileColumnToggle(4);
    mobileColumnToggle(5);
    mobileColumnToggle(6);

// TOOLTIP FUNCTIONALITY

    new Tooltips().initialize();

// SCROLL FUNCTIONALITY

    // function that manages all behaviour when the user scrolls inside the browser window
    scrollBehaviour();

// RESIZE FUNCTIONALITY

    // Behaviour that is responsible for equalizing the height of columns.
    equalizeColumnHeightBehaviour();

// HARMONICA FUNCTIONALITY

    new HarmonicaDropDown().initialize($('.harmonica-toggle'));

// POPUP FUNCTIONALITY

    // add the popup window functionality to a popup trigger
    addPopupTrigger();

// NAVIGATION BAR FUNCTIONALITY

    new NavigationMenu().initialize();

// SIDEBAR FUNCTIONALITY

    // populate the sidebar
    populateSidebar();

    // add behaviour to toggle the sidebar
    sideBarToggle();

// FOOTER FUNCTIONALITY

    // add behaviour to the footer
    footerBehaviour();

// COOKIE BAR FUNCTIONALITY

    // add behaviour to the cookie bar
    new CookieBar().initialize();

// COOKIE WALL FUNCTIONALITY

    // add behaviour to the cookie wall
    new CookieWall().initialize();

// INNER PAGE LINKING

    // On click scroll to related 'inner-link' element.
    new InnerPageLinking().initialize();

// RESPONSIVE TABLE

    // All behaviour regarding responsive tables.
    responsiveTableBehaviour($('body'));

// SLIDER

    SimpleSlider.initialize();

// PAGE LOAD FUNCTIONALITY

    // Make the website fade in on page load.
    fadeInOnLoad($('#site-wrapper'));

// POP-OUT COLUMNS

    new PopOutColumns().initialize();

// SET RELATION ID COOKIE

    new RelationIdCookie().initialize();

// SET FULL SCREEN BANNERS

    new FullScreenBanner().initialize();

// SIMPLE POPUP

    new SimplePopup().initialize();

});

// POP-OUT COLUMNS

    function PopOutColumns() {

        var scrollPanel = $('.pop-outs');
        var popOutsColumn = $('.pop-outs div[class^="column"]');
        var hasMultipleColumns = scrollPanel.find('div[class^="column"]:nth-child(2)').length;

        this.initialize = function() {
            togglePopOutClasses();
            if (hasMultipleColumns) {
                addMobileArrowNavigation();
                setDefaultScrollPosition();
                return;
            }
            scrollPanel.addClass('single-column');
        };

        var togglePopOutClasses = function() {
            toggleClassesPageLoad();
            toggleClassesMouseEnter();
            toggleClassesMouseLeave();
        };

        var toggleClassesPageLoad = function() {
            scrollPanel.each(function() {
                var currentScrollPanel = $(this);
                var allColumns = currentScrollPanel.find('div[class^="column"]');
                var defaultPopOutExists = currentScrollPanel.find('.default').length;
                if (!defaultPopOutExists) {
                    allColumns.removeClass('defuse');
                }
            });
        };

        var toggleClassesMouseEnter = function() {
            popOutsColumn.mouseenter(function() {
                var activeColumn = $(this);
                var allColumns = activeColumn.closest('.pop-outs').find('div[class^="column"]');
                allColumns.removeClass('pop-out').addClass('defuse');
                activeColumn.removeClass('defuse').addClass('pop-out');
            });
        };

        var toggleClassesMouseLeave = function() {
            popOutsColumn.mouseleave(function() {
                var popOutColumn = $(this);
                var allColumns = popOutColumn.closest('.pop-outs').find('div[class^="column"]');
                var defaultPopOut = popOutColumn.closest('.pop-outs').find('.default');
                allColumns.removeClass('defuse');
                popOutColumn.removeClass('pop-out');
                if (defaultPopOut.length) {
                    allColumns.addClass('defuse');
                    defaultPopOut.removeClass('defuse');
                }
            });
        };

        var addMobileArrowNavigation = function() {
            if (hasMultipleColumns) {
                populateNavigation();
                var navigationWrapper = scrollPanel.closest('.pop-outs-navigation');
                navigationWrapper.find('.navigate-left').click(function() {
                    toggleBackwards($(this));
                });
                navigationWrapper.find('.navigate-right').click(function() {
                    toggleForwards($(this));
                });
            }
        };

        var populateNavigation = function() {
            scrollPanel.wrap('<div class="pop-outs-navigation"></div>');
            scrollPanel.parent().append(
                '<span class="navigate-left"></span><span class="navigate-right"></span>'
            );
        };

        var toggleForwards = function(trigger) {
            var navigationWrapper = trigger.closest('.pop-outs-navigation');
            var activeColumn = navigationWrapper.find('div[class^="column"].active');
            var nextColumn = activeColumn.next('div[class^="column"]');
            var nextColumnExists = nextColumn.length;
            var backwardsTrigger = navigationWrapper.find('.navigate-left');
            if (nextColumnExists) {
                scrollToColumn(nextColumn, 400);
                backwardsTrigger.fadeIn(300);
            }
            if (nextColumn.is(':last-child')) {
                trigger.fadeOut(300);
            }
        };

        var toggleBackwards = function(trigger) {
            var navigationWrapper = trigger.closest('.pop-outs-navigation');
            var activeColumn = navigationWrapper.find('div[class^="column"].active');
            var previousColumn = activeColumn.prev('div[class^="column"]');
            var previousColumnExists = previousColumn.length;
            var forwardTrigger = navigationWrapper.find('.navigate-right');
            if (previousColumnExists) {
                scrollToColumn(previousColumn, 400);
                forwardTrigger.fadeIn(300);
            }
            if (previousColumn.is(':first-child')) {
                trigger.fadeOut(300);
            }
        };

        var setDefaultScrollPosition = function() {
            scrollPanel.each(function() {
                var scrollPanel = $(this);
                var defaultColumn = scrollPanel.find('.default');
                var defaultColumnIsSet = defaultColumn.length;
                if (defaultColumnIsSet) {
                    scrollToColumn(defaultColumn, 0);
                    return;
                }
                scrollToColumn(scrollPanel.find('div[class^="column"]:first'), 0);
            });
        };

        var scrollToColumn = function(column, duration) {
            var scrollPanelWidth = scrollPanel.outerWidth();
            var columnWidth = column.width();
            var columnCenterMargin = (scrollPanelWidth - columnWidth) / 2;
            var columnHorizontalPosition = column.position().left;
            var scrollPosition = columnHorizontalPosition - columnCenterMargin;
            scrollPanel.animate({
                scrollLeft: scrollPosition
            }, duration);
            column.closest('.pop-outs').find('div[class^="column"]').removeClass('active');
            column.addClass('active');
        };
    }

// RESPONSIVE FUNCTIONALITY

    // Add's touch class to body element when the device used is a touch device.

    function MobileDeviceRecognition() {

        var scope = this;

        this.initialize = function() {
            var isTouchDevice = this.hasTouch();
            var isOldAndroidDevice = this.hasOldAndroid();
            var isAndroidDevice = this.hasAndroid();
            if (isTouchDevice) {
                $('body').addClass('touch');
            }
            if (isOldAndroidDevice) {
                $('body').addClass('old-android');
            }
            if (isAndroidDevice) {
                $('body').addClass('android');
            }
        };

        this.hasTouch = function() {
            return typeof window.ontouchstart !== 'undefined';
        };

        this.hasOldAndroid = function() {
            var userAgent = navigator.userAgent;
            if( userAgent.indexOf("Android") >= 0 ) {
                var androidVersion = parseFloat(userAgent.slice(userAgent.indexOf("Android")+8));
                return androidVersion < 5.0;
            }
        };

        this.hasAndroid = function() {
            return navigator.userAgent.match(/Android/i);
        };

        this.hasIOS = function() {
            return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        };

        this.isIPad = function() {
            return navigator.userAgent.match(/iPad/i) != null;
        };

        this.isIPhone = function() {
            return navigator.platform.match(/i(Phone|Pod)/i);
        };

        this.getIosVersionArray = function() {
            if (scope.hasIOS()) {
                var version = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                return [parseInt(version[1], 10), parseInt(version[2], 10), parseInt(version[3] || 0, 10)];
            }
            return [];
        };

        this.getIosVersion = function() {
            if (scope.hasIOS()) {
                var versionArray = scope.getIosVersionArray();
                return parseFloat(versionArray.join('.'));
            }
            return null;
        };
    }

    // function that makes a multiple column layout do a harmonica collapse on mobile phones
    // (based on transitions in media queries in CSS)
    function mobileColumnToggle(columns) {
        $('.collapse .column-header').click(function() {
            var wrapper = $(this).closest('.column-' + columns);
            if (wrapper.hasClass('open')) {
                wrapper.removeClass('open').addClass('closed');
            } else {
                $(this).closest('.collapse').find('.column-' + columns).addClass('closed').removeClass('open');
                wrapper.removeClass('closed').addClass('open');
            }
        });
    }

// TOOLTIP FUNCTIONALITY

    function Tooltips() {

        this.initialize = function(toolTip) {
            var hasTouch = $('body').hasClass('touch');
            var toolTips = typeof toolTip != 'undefined' ? toolTip : $('.tooltip');
            if (hasTouch) {
                toolTips.click(function() {
                    toggle(this);
                });
                clickAway('.tooltip-popup', 'tooltip');
            } else {
                toolTips.mouseleave(function() {
                    toggle(this);
                });
                toolTips.mouseenter(function() {
                    toggle(this);
                });
            }
        };

        var toggle = function(scope) {
            var tooltipContent = $(scope).html();
            var tooltipPopup = $('.tooltip-popup');
            var tooltipPopupExists = tooltipPopup.length;
            if (tooltipPopupExists) {
                $('.tooltip-popup').remove();
            } else {
                var createTooltipPopup = $('<div class="tooltip-popup">' + tooltipContent +'</div>').fadeIn(200);
                $('body').append(createTooltipPopup);
                setPosition(scope);
            }
        };

        var setPosition = function(scope) {
            var tooltip = $(scope);
            var tooltipPopup = $('.tooltip-popup');
            var tooltipWidth = tooltip.innerWidth();
            var tooltipHeight = tooltip.innerHeight();
            var tooltipLeft = tooltip.offset().left;
            var tooltipRight = $('body').outerWidth() - tooltipLeft - tooltipWidth;
            var tooltipY = tooltip.offset().top;
            var tooltipPopupWidth = tooltipPopup.innerWidth();
            var tooltipPopupHeight = tooltipPopup.innerHeight();
            var verticalOffset = 10;
            var horizontalOffset = 10;
            var tooltipPopupLeft = tooltipLeft;
            var tooltipPopupRight = tooltipLeft - tooltipPopupWidth + tooltipWidth;
            var tooltipPopupCenter = tooltipLeft + (tooltipWidth / 2) - (tooltipPopupWidth / 2);
            var tooltipPopupTop = tooltipY - tooltipPopupHeight - verticalOffset;
            var tooltipPopupBottom = tooltipY + verticalOffset + tooltipHeight;
            var tooCloseToTop = tooltipPopupHeight + verticalOffset >= tooltipY;
            var tooCloseToLeft = tooltipPopupWidth / 2 + horizontalOffset >= tooltipLeft;
            var tooCloseToRight = tooltipPopupWidth / 2 + horizontalOffset >= tooltipRight;
            if (tooCloseToTop) {
                tooltipPopup.css('top', tooltipPopupBottom + 'px');
            } else {
                tooltipPopup.css('top', tooltipPopupTop + 'px');
            }
            if (tooCloseToLeft) {
                tooltipPopup.css('left', tooltipPopupLeft + 'px');
            } else if (tooCloseToRight) {
                tooltipPopup.css('left', tooltipPopupRight + 'px');
            } else {
                tooltipPopup.css('left', tooltipPopupCenter + 'px');
            }
        };

        var clickAway = function (elementId, exceptionId) {
            $(document).click(function(event) {
                if ($(elementId).is(':visible') && !$(event.target).hasClass(exceptionId)) {
                    $(elementId).remove();
                }
            });
        };

    }

// SCROLL FUNCTIONALITY

    // function that manages all behaviour when the user scrolls inside the browser window
    function scrollBehaviour() {

        // register when the user scrolled inside the browser window
        $(window).scroll(toggleHeader);
    }

    // toggle the header visibility
    function toggleHeader() {
        var offset = 0;
        var headerHeight = $('#navigation-bar').outerHeight();
        var distanceFromTop = $(this).scrollTop();

        // if the scroll distance is greater then the starting position (initial offset)
        // and the distance from the top of the document is greater then the height of the header
        // then slide the header upwards (by adding add a class)
        // else, slide it downwards (by removing a class)
        if (distanceFromTop > offset && distanceFromTop > headerHeight) {
            $('#navigation-bar').addClass('header-up');
        } else {
            $('#navigation-bar').removeClass('header-up');
        }

        // set the new offset value
        offset = distanceFromTop;
    }

// RESIZE FUNCTIONALITY

    // Behaviour that handles all height alterations on columns.
    function equalizeColumnHeightBehaviour() {

        // Equalize the height of the columns when the window loads.
        $(window).load(equalizeColumnHeight);

        // Equalize the height of the columns when the window resizes.
        $(window).resize(equalizeColumnHeight);
    }

    // Make all columns in the same row equall in height.
    function equalizeColumnHeight(scope) {
        $('.row.equal').each(function() {

            // Reset the minimum height of the columns.
            $(this).find('.content').css('min-height', 'auto');

            var heighestColumn = findHeighestColumn(this);
            updateColumnHeight(this, heighestColumn);
        });
    }

    // Find the heighest column in a row and return the height.
    function findHeighestColumn(row) {
        var currentHeighest = 0;
        var heighestColumn;
        $(row).find('div[class^="column-"]').each(function() {
            var contentHeight = $(this).innerHeight();
            if (contentHeight > currentHeighest) {
                currentHeighest = contentHeight;
                heighestColumn = this;
            }
        });
        return heighestColumn;
    }

    // Equalize the height of all the columns in a row.
    function updateColumnHeight(row, heighestColumnHeight) {
        var contentHeight = $(heighestColumnHeight).find('.content').innerHeight();
        var headerHeight = $(heighestColumnHeight).find('.column-header').innerHeight();

        $(row).children('div[class^="column-"]').each(function() {
            var currentColumn = $(this);
            var currentContent = currentColumn.children('.content');
            var currentHeaderHeight = $(this).find('.column-header').innerHeight();
            var rowWidth = $(row).innerWidth();
            var columnWidth = $(this).innerWidth();
            var border = parseInt(currentContent.css('border-top-width')) + parseInt(currentContent.css('border-bottom-width'));

            // Check if the columns are displayed horizontally ( < 0.7 is the same as < 70% of the row size
            // what would mean that in our grid it will have a column next to it). We check for this so that
            // if the columns are displayed vertically (on mobile devices) they wont equalize and show properly.
            if (columnWidth / rowWidth < 0.7) {

                // See if we have to add extra height to the content when it has a smaller column-header.
                if (currentHeaderHeight < headerHeight) {
                    var headerHeightDifference = headerHeight - currentHeaderHeight;
                    currentContent.css('min-height', contentHeight + border + headerHeightDifference +'px');

                // See if we have to remove height to the content when it has a bigger column-header.
                } else if (currentHeaderHeight > headerHeight) {
                    var headerHeightDifference = currentHeaderHeight - headerHeight;
                    currentContent.css('min-height', contentHeight + border - headerHeightDifference +'px');
                } else {
                    currentContent.css('min-height', contentHeight + border + 'px');
                }
            }
        });
    }

// HARMONICA FUNCTIONALITY

    function HarmonicaDropDown() {

        var checkScreen = new CheckScreen();

        this.initialize = function(harmonicaToggle) {
            var harmonicaToggle = typeof harmonicaToggle != 'undefined' ? harmonicaToggle : $('.harmonica-toggle');
            setStartState(harmonicaToggle);
            setToggle(harmonicaToggle);
        };

        var setStartState = function(harmonicaToggle) {
            harmonicaToggle.each(function() {
                var toggle = $(this);

                if (toggle.hasClass('up') || toggle.hasClass('down')) {
                    if (toggle.hasClass('down')) {
                        if (toggle.hasClass('labeled')) {
                            toggle.next('.harmonica-content').css('display','flex');
                        }
                        else {
                            toggle.next('.harmonica-content').show();
                        }
                    }
                } else {
                    toggle.addClass('up');
                }
            });
        };

        var setToggle = function(harmonicaToggle) {
            harmonicaToggle.click(function(e) {
                e.preventDefault();
                var toggle = $(this);
                var harmonica = toggle.closest('.harmonica');
                var isPaired = harmonica.is("[paired]");
                var isMobile = checkScreen.mobile();

                if (isPaired && !isMobile) {
                    var pairedValue = harmonica.attr("paired");
                    harmonica = $(".harmonica[paired='" + pairedValue + "']");
                    toggle = harmonica.children('.harmonica-toggle');
                }

                var nextContent = toggle.next('.harmonica-content');
                var firstToggle = harmonica.children('.harmonica-toggle:first');
                var slideDuration = 500;

                if (toggle.hasClass('up')) {
                    harmonica.children('.harmonica-toggle').removeClass('down').addClass('up');
                    harmonica.children('.harmonica-content').slideUp(slideDuration);
                    toggle.removeClass('up').addClass('down');
                    if (toggle.hasClass('labeled')) {
                        nextContent.css('display','flex');
                    }
                    else {
                        nextContent.slideDown();
                    }
                    responsiveTableBehaviour(harmonica);
                    scrollToFirstDropDown(toggle, firstToggle, slideDuration, harmonica);
                } else {
                    toggle.removeClass('down').addClass('up');
                    nextContent.slideUp(slideDuration);
                }
            });
        };

        var scrollToFirstDropDown = function(toggle, firstToggle, slideDuration, harmonica) {
            var isMobileResolution = checkScreen.mobile();
            var isLineHarmonica = toggle.closest('.harmonica').hasClass('line');
            var scrollTo = new InnerPageLinking();

            if (!isLineHarmonica || !isMobileResolution || doNotScroll(harmonica)) {
                return;
            }
            scrollTo.scrollToElement(toggle, slideDuration, slideDuration);
        };

        var doNotScroll = function(harmonica) {
            harmonica.hasClass('no-scroll');
        };

    }

// POPUP FUNCTIONALITY

    // add the popup window functionality to a popup trigger
    function addPopupTrigger() {
        $('.popup-trigger').on("click", function(e) {
            var trigger = $(this);
            var scope = trigger.closest('.popup-container');
            e.preventDefault();
            popupBehaviour(scope);
        });
    }

    // function that combines all the behaviour of the popup window
    function popupBehaviour(scope) {
        populatePopup(scope);
        showPopup(scope);
        addClosePopupEvent(scope);
        responsiveTableBehaviour(scope);
    }


    // populate the popup window with a close button and a background
    function populatePopup(scope) {
        var backgroundExists = scope.find('.popup-background').length;
        var closeButtonExists = scope.find('.close-popup').length;
        if (!backgroundExists && !closeButtonExists) {
            scope.find('.popup-title').append('<span class="close-popup"></span>');
            scope.append('<div class="popup-background"></div>');
        }
    }

    // show the popup window and its components
    function showPopup(scope) {
        scope.find('.popup-background').fadeIn(200);
        scope.find('.popup').fadeIn(200);
        setTimeout(function() {
            setPopupHeight(scope);
        }, 1);
    }

    // Set popup height based on content.
    function setPopupHeight(scope) {
        var popup = scope.find('.popup');
        var content = scope.find('.content');
        var titleHeight = scope.find('.popup-title').outerHeight(true);
        var contentHeight = content.outerHeight(true);
        popup.height(contentHeight + titleHeight + 40);
    }

    // close the popup window clicking on the background or the close button
    function addClosePopupEvent(scope) {
        scope.find('.popup-background').click(function() {
            hidePopup(scope);
        });
        scope.find('.close-popup').click(function() {
            hidePopup(scope);
        });
        scope.find('.trigger-close-popup').click(function() {
            hidePopup(scope);
        });
    }

    // hides the popup window and its components
    function hidePopup(scope) {
        scope.find('.popup-background').fadeOut(200);
        scope.find('.popup').fadeOut(200);
    }

// SIDEBAR FUNCTIONALITY

    // populate the sidebar with a close button (other features can be be added later)
    function populateSidebar() {
        $('#sidebar').find('.sidebar-navigation').append('<span class="close-sidebar"></span>');
    }

    // toggles sidebar
    function sideBarToggle() {
        openSidebar();
        closeSidebar();
        clickAway('#sidebar', 'sidebar-toggle');
    }

    // opens sidebar
    function openSidebar() {
        $('.sidebar-toggle').click(function() {
            if (!$('#sidebar').hasClass('opened')) {
                $('#sidebar').removeClass('closed').addClass('opened');
            }
        });
    }

    // closes sidebar
    function closeSidebar() {
        $('.close-sidebar').click(function() {
            if (!$('#sidebar').hasClass('closed')) {
                $('#sidebar').removeClass('opened').addClass('closed');
            }
        });
    }

// NAVIGATION BAR FUNCTIONALITY

    function NavigationMenu() {

        this.initialize = function () {
            generalMenuBehaviour();
            customerNumberSelectBehaviour();
            innerMenuDropDownBehaviour();
            new SubMenuNavigation().initialize();
        };

        var generalMenuBehaviour = function() {
            navigationMenuAddCloseButton();
            navigationMenuToggle();
            closeNavigationMenuCloseClicked('.navigation-menu a');
            closeNavigationMenuCloseClicked('.navigation-menu ul .close');
            clickAway('.navigation-menu');
        };

        var navigationMenuAddCloseButton = function() {
            $('.navigation-menu > ul').prepend('<span class="close"></span>');
        };

        var navigationMenuToggle = function() {
            $('.navigation-menu').click(function() {
                var scope = $(this);
                var menu = scope.find('ul');
                if (!scope.hasClass('opened')) {
                    $('.navigation-menu').removeClass('opened').addClass('closed');
                    scope.removeClass('closed').addClass('opened');
                }
            });
        };

        var closeNavigationMenuCloseClicked = function(element) {
            $(element).click(function() {
                var trigger = $(this);
                var linkHasNoUrl = trigger.closest('a').length && trigger.attr('href') == '' || trigger.hasClass('dropdown-trigger');
                var isSubMenuLink = trigger.hasClass('submenu-trigger') || trigger.parents('.submenu-list').length;
                if (linkHasNoUrl || isSubMenuLink) {
                    return;
                }
                setTimeout(function() {
                    trigger.closest('.navigation-menu').removeClass('opened').addClass('closed');
                }, 5);
            });
        };

        var customerNumberSelectBehaviour = function() {
            var customerNumberSelect = '.customer-number-select';
            customerNumberSelectToggle(customerNumberSelect);
            customerNumberSelectclickAway(customerNumberSelect);
            selectCustomerNumber(customerNumberSelect);
        };

        var customerNumberSelectToggle = function(elementId) {
            $(elementId).find('.select-button').click(function() {
                var select = $(this).closest(elementId);
                if (select.hasClass('opened')) {
                    select.removeClass('opened').addClass('closed');
                    select.find('.customer-number-list').slideUp(200);
                } else {
                    select.removeClass('closed').addClass('opened');
                    select.find('.customer-number-list').slideDown(200);
                }
            });
        };

        var customerNumberSelectclickAway = function(elementId) {
            $(document).click(function(event) {
                if (!$(event.target).closest(elementId).length) {
                    if ($(elementId).hasClass('opened')) {
                        $(elementId).removeClass('opened').addClass('closed');
                        $(elementId).find('.customer-number-list').slideUp();
                    }
                }
            });
        };

        var selectCustomerNumber = function(elementId) {
            $(elementId).find(".customer-number a").click(function(event) {
                var url = $(this).attr("href");

                event.preventDefault();
                addSpinner($(this));

                setTimeout(function(){
                    window.location = url;
                }, 200);
            });
        };

        var addSpinner = function(scope) {
            var dropDownSpinner = new Spinner(scope, $('.navigation-menu.mijn-budget ul'));
            dropDownSpinner.add();
            dropDownSpinner.show();
        };

        var innerMenuDropDownBehaviour = function() {
            var dropDownTriggers = $('.navigation-menu > ul li a.dropdown-trigger');

            setInnerMenuStartState(dropDownTriggers);

            dropDownTriggers.click(function(event) {
                var dropDownTrigger = $(this);
                toggleMenuDropDown(dropDownTrigger);
            });
        };

        var setInnerMenuStartState = function(dropDownTriggers) {
            dropDownTriggers.each(function() {
                var toggle = $(this);
                if (toggle.hasClass('down')) {
                    toggle.next('.dropdown-list').show();
                }
            });
        };

        var toggleMenuDropDown = function(dropDownTrigger) {
            var menu = dropDownTrigger.closest('ul');
            var dropDownTriggers = menu.find('.dropdown-trigger');
            var dropDownList = dropDownTrigger.next('.dropdown-list');
            var dropDownLists = menu.find('.dropdown-list');
            var expandedClass = "expanded";
            var downClass = "down";
            var dropDownListIsDown = dropDownTrigger.hasClass(downClass);
            var duration = 200;
            if (dropDownListIsDown) {
                dropDownList.slideUp(duration);
                dropDownTrigger.removeClass(downClass);
                menu.removeClass(expandedClass);
            } else {
                dropDownLists.slideUp(duration);
                dropDownTriggers.removeClass(downClass);
                dropDownList.slideDown(duration);
                dropDownTrigger.addClass(downClass);
                menu.addClass(expandedClass);
            }
        };

        function SubMenuNavigation() {
            var expandedClass = "expand";
            var downClass = "down";
            var activeClass = "active";
            var originalActiveClass = "original-active";
            var upClass = "up";
            var subMenuTriggerClass = "submenu-trigger";
            var subMenuListClass = "submenu-list";
            var disableActiveClass = "disable-active"
            var duration = 200;

            var checkScreen = new CheckScreen();
            var navigationMenu = $('.navigation-menu.primary');
            var dropDownTriggers = $('.navigation-menu.primary > ul li a.' + subMenuTriggerClass);
            var navigationMenuList = $('.navigation-menu.primary > ul');
            var activeNavigationLinks = $('.navigation-menu.primary > ul li a.' + activeClass);
            var activeSubMenuTrigger = navigationMenu.find('.' + subMenuTriggerClass + '.' + activeClass);

            this.initialize = function() {

                setupSubMenu();

                window.onresize = function() {
                    var isTablet = checkScreen.innerTablet();
                    var isMobile = checkScreen.innerMobile();

                    if (isTablet || isMobile) {
                        return;
                    }

                    resetSubMenu();
                };

                dropDownTriggers.click(function(event) {
                    var dropDownTrigger = $(this);
                    var isTablet = checkScreen.innerTablet();
                    var isMobile = checkScreen.innerMobile();

                    if (isTablet || isMobile) {
                        event.preventDefault();
                        toggleSubMenuDropDown(dropDownTrigger);
                    }
                });

                dropDownTriggers.closest('li').mouseenter(function() {

                    toggleHover($(this));

                });

                dropDownTriggers.closest('li').mouseleave(function() {

                    toggleHover($(this));

                });

            };

            var setupSubMenu = function() {
                activeNavigationLinks.addClass(originalActiveClass);

                if (activeSubMenuTrigger.length) {
                    navigationMenuList.addClass(expandedClass);
                }
            };

            var toggleSubMenuDropDown = function(dropDownTrigger) {
                var menu = dropDownTrigger.closest('ul').closest('ul');
                var dropDownTriggers = menu.find("." + subMenuTriggerClass);
                var dropDownList = dropDownTrigger.next("." + subMenuListClass);
                var dropDownLists = menu.find("." + subMenuListClass);
                var dropDownListIsDown = dropDownTrigger.hasClass(downClass);


                if (dropDownListIsDown) {
                    dropDownList.slideUp(duration);
                    dropDownTrigger.removeClass(downClass);
                    dropDownTrigger.removeClass(activeClass);
                    menu.removeClass(expandedClass);
                    dropDownTrigger.addClass(upClass);
                } else {
                    dropDownLists.slideUp(duration);
                    dropDownTriggers.removeClass(downClass);
                    dropDownTriggers.removeClass(activeClass);
                    dropDownTriggers.addClass(upClass);
                    dropDownList.slideDown(duration);
                    dropDownTrigger.addClass(downClass);
                    dropDownTrigger.addClass(activeClass);
                    dropDownTrigger.removeClass(upClass);
                    menu.addClass(expandedClass);
                }
            };

            var resetSubMenu = function() {
                var navigationMenuElements = navigationMenu.children();
                var navigationMenuLinks = navigationMenu.find('a');


                navigationMenuElements.find('*').each(function() {
                    $(this).removeAttr('style');
                });

                navigationMenuLinks.removeClass(activeClass + " " + downClass);
                navigationMenuLinks.closest("." + subMenuTriggerClass).addClass(upClass);

                navigationMenu.find("." + originalActiveClass).each(function() {
                    var activeLink = $(this);

                    if (activeLink.hasClass(subMenuTriggerClass)) {
                        activeLink.removeClass(upClass);
                        activeLink.addClass(downClass);
                    }

                    activeLink.addClass(activeClass);
                });
            };

            var toggleHover = function(dropDownListItem) {

                var subMenuTrigger = dropDownListItem.children('a');
                var isTablet = checkScreen.innerTablet();
                var isMobile = checkScreen.innerMobile();

                if (isTablet || isMobile || subMenuTrigger.hasClass(originalActiveClass)) {
                    return;
                }

                var activeSubMenu = subMenuTrigger.closest('ul').find('.' + originalActiveClass);

                if (activeSubMenu.hasClass(disableActiveClass)) {
                    activeSubMenu.removeClass(disableActiveClass);

                    return;
                }

                activeSubMenu.addClass(disableActiveClass);
            };

        }

    }

// FOOTER BAR FUNCTIONALITY

    // add behaviour to the footer
    function footerBehaviour() {
        $('.mobile-collapse li:first-child').click(function() {
            var collapseColumn = $(this).closest('.mobile-collapse');
            cssToggle(collapseColumn);
        });
    }

    function CookieBar() {

        var cookieBar = $('#cookie-bar');
        var popup = $('#cookie-popup');
        var accept = $('#accept-cookies');
        var configure = $('#configure-cookies');
        var cancel = popup.find('.grey-button');
        var submit = popup.find('.blue-button');
        var duration = 200;

        this.initialize = function() {

            toggleBar();

            openComponent(configure, popup);

            closeComponent(cancel, popup);
            closeComponent(submit, popup);

            closeComponent(accept, cookieBar);
            closeComponent(submit, cookieBar);

            setCookies(submit);
            setCookies(accept, true);

        };

        var toggleBar = function() {

            if (
                cookieBehaviour.getCookie(cookieBehaviour.information.cookieName) == cookieBehaviour.information.shown ||
                cookieBehaviour.getCookie(cookieBehaviour.information.cookieName) == cookieBehaviour.information.shownNecessary
            ) {

                cookieBar.fadeIn(duration);

            }

        };

        var openComponent = function(trigger, component) {

            trigger.click(function() {

                component.fadeIn(duration);

            });

        };

        var closeComponent = function(trigger, component) {

            trigger.click(function() {

                component.fadeOut(duration);

            });

        };

        var setCookies = function(trigger, allAccepted) {

            trigger.click(function() {

                if (typeof allAccepted == 'undefined') {

                    var optimalSelected = parseInt(popup.find('input[type=radio]:checked').val());

                } else {

                    var optimalSelected = allAccepted;

                }

                if (optimalSelected) {

                    cookieBehaviour.setCookie(
                        cookieBehaviour.information.cookieName,
                        cookieBehaviour.information.shownAgreed,
                        cookieBehaviour.information.cookieDuration)
                    ;

                } else {

                    cookieBehaviour.setCookie(
                        cookieBehaviour.information.cookieName,
                        cookieBehaviour.information.agreedNecessary,
                        cookieBehaviour.information.cookieDuration)
                    ;

                }

            });

        };

    }

    function CookieWall() {

        var optionalPixelsIncluded = false;
        var cookieWallId = 'cookie-wall';
        var cookieSettingsId = 'cookie-settings';
        var necessaryConsentClass = 'necessary-consent';
        var fullConsentClass = 'full-consent';
        var necessarySettingClass = 'necessary';
        var optimalSettingClass = 'optimal';
        var saveSettingClass = 'set-cookie-preference';
        var duration = 200;

        this.initialize = function() {

            toggleWall();
            toggleSettings();
            initPixels();

        };

        var initPixels = function () {

            if (cookieBehaviour.getCookie(cookieBehaviour.information.cookieName) == cookieBehaviour.information.shownAgreed) {
                setOptimalPixels();
            }

        };

        var toggleWall = function() {

            if (
                cookieBehaviour.getCookie(cookieBehaviour.information.cookieName) == cookieBehaviour.information.shown ||
                cookieBehaviour.getCookie(cookieBehaviour.information.cookieName) == cookieBehaviour.information.shownNecessary
            ) {

                $('#' + cookieWallId).fadeIn(duration);
                toggleConsentOptions();
                closeComponent($('#' + cookieWallId + ' .' + necessaryConsentClass), $('#' + cookieWallId));
                closeComponent($('#' + cookieWallId + ' .' + fullConsentClass), $('#' + cookieWallId));
                setCookies($('#' + cookieWallId + ' .' + necessaryConsentClass), false);
                setCookies($('#' + cookieWallId + ' .' + fullConsentClass), true);

            }

        };

        var toggleConsentOptions = function() {

            $('#' + cookieWallId + ' .custom-consent-toggle').on('click', function() {

                $('#' + cookieWallId + ' .default-consent-content').slideUp(duration);
                $('#' + cookieWallId + ' .custom-consent-content').slideDown(duration);

            });

        };

        var toggleSettings = function() {

            if (cookieBehaviour.getCookie(cookieBehaviour.information.cookieName) == cookieBehaviour.information.shownAgreed) {

                setCookieSetting(optimalSettingClass);

            } else {

                setCookieSetting(necessarySettingClass);

            }

            $('#' + cookieSettingsId + ' .' + saveSettingClass).on('click', function() {

                if ($('#' + cookieSettingsId + ' .' + optimalSettingClass + ' input').is(':checked')) {

                    setOptimalCookie();
                    setOptimalPixels();

                } else {

                    setNecessaryCookie();

                }

            });

        };

        var setCookieSetting = function(settingClass) {

            var cookieSettingInput = $('#' + cookieSettingsId + ' .' + settingClass);

            if (cookieSettingInput.length) {
                cookieSettingInput.click();
            }

        };

        var setCookies = function(trigger, allAccepted) {

            trigger.on('click', function() {

                if (allAccepted) {

                    setOptimalCookie();
                    setOptimalPixels();
                    setCookieSetting(optimalSettingClass);

                } else {

                    setNecessaryCookie();
                    setCookieSetting(necessarySettingClass);

                }

            });

        };

        var setNecessaryCookie = function() {

            cookieBehaviour.setCookie(
                cookieBehaviour.information.cookieName,
                cookieBehaviour.information.agreedNecessary,
                cookieBehaviour.information.cookieDuration)
            ;

        };

        var setOptimalCookie = function() {

            cookieBehaviour.setCookie(
                cookieBehaviour.information.cookieName,
                cookieBehaviour.information.shownAgreed,
                cookieBehaviour.information.cookieDuration)
            ;

        };

        var setOptimalPixels = function () {

            if (optionalPixelsIncluded) {
                return;
            }

            if (typeof LoadGTMOptionalPixels == 'function') {

                try {
                    var gtmPixels = new LoadGTMOptionalPixels();

                    gtmPixels.load();

                } catch (err) {

                    return;

                }

                optionalPixelsIncluded = true;
            }

        };

        var closeComponent = function(trigger, component) {

            trigger.click(function() {

                component.fadeOut(duration);

            });

        };

    }


// SPINNER FUNCTIONALITY

    function Spinner(spinnerTriggerElement, spinnerParentElement) {

        var spinnerScopeElement = spinnerTriggerElement.closest(spinnerParentElement);

        this.add = function() {
            if (!scopeElementsExist() || spinnerExists()) {
                return;
            }
            var spinnerText = Translator.trans('formOverlay.spinner.spinnerText');
            spinnerScopeElement.append(
                '<div class="spinner">' +
                    '<span class="spinner-text">' +
                        spinnerText +
                    '</span>' +
                '</div>'
            );
        };

        this.show = function() {
            if (!scopeElementsExist() || !spinnerExists()) {
                return;
            }
            toggleSubmitButton();
            spinnerParentElement.find('.spinner').fadeIn(100);
        };

        this.hide = function() {
            if (!scopeElementsExist() || !spinnerExists()) {
                return;
            }
            toggleSubmitButton();
            spinnerParentElement.find('.spinner').fadeOut(100);
        };

        var scopeElementsExist = function() {
            var spinnerScopeElementExists = spinnerScopeElement.length;
            var spinnerTriggerElementExists = spinnerTriggerElement.length;
            return spinnerScopeElementExists || spinnerTriggerElementExists;
        };

        var spinnerExists = function() {
            return spinnerScopeElement.find('.spinner').length;
        };

        var toggleSubmitButton = function() {
            var submitButton = spinnerScopeElement.find('.form-item.submit input');
            var submitButtonExists = submitButton.length;
            var submitButtonIsVisible = submitButton.is(':visible');
            if (!submitButtonExists) {
                return;
            }
            if (submitButtonIsVisible) {
                submitButton.hide();
                return;
            }
            submitButton.show();
        };

    }

// PAGE LOAD FUNCTIONALITY

    // Make the website fade in on page load
    function fadeInOnLoad(element) {
        var fadeDuration = 0;
        element.fadeIn(fadeDuration);
    }

// INNER PAGE LINKING

    function InnerPageLinking() {

        var scope = this;

        this.initialize = function() {

            $('a[inner-link]').on('click', function(e) {

                e.preventDefault();

                var innerLink = $(this);
                var innerLinkValue = innerLink.attr('inner-link');
                var relatedBlock = $('[inner-link="' + innerLinkValue +'"]').not(innerLink);

                scope.scrollToElement(relatedBlock,1000);

            });

        };

        this.scrollToElement = function(scrollToElement, duration, delay) {

            var setDelay = delay || 0;

            setTimeout(function() {
                var navigationBar = $('#navigation-bar');
                var navigationHeight = navigationBar.innerHeight();
                var submenu = navigationBar.find('.submenu-list');

                if (submenu.length) {

                    navigationHeight = navigationHeight + submenu.innerHeight();

                }

                var scrollLocation = scrollToElement.offset().top - navigationHeight;

                $('html, body').animate({
                    scrollTop: scrollLocation
                }, duration);

            }, setDelay);

        }

    }

// RESPONSIVE TABLE

    // All behaviour regarding responsive tables.
    function responsiveTableBehaviour(scopeElement) {
        scopeElement.find('.responsive-table:visible').each(function() {
            var table = $(this);
            var scrollActive = tableHasScrollBar(table);
            var animationExists = table.next().hasClass('swipe-animation');
            var isTouchDevice = $('body').hasClass('touch');
            if (scrollActive && !animationExists && isTouchDevice) {
                table.after(
                    '<div class="swipe-animation"></div>'
                );
            }
        });
    }

    // Check if the table has an active scrollbar.
    function tableHasScrollBar(table) {
        var tableWidth = table.innerWidth();
        var rowWidth = table.find('tr:first').innerWidth();
        return rowWidth > tableWidth;
    }

// SIMPLE SLIDER

    var SimpleSlider = {};

    SimpleSlider.initialize = function() {
        $('.slider').each(function() {
            var slider = $(this);
            SimpleSlider.addSpans(slider);
            SimpleSlider.initializeNavigation(slider);
        });
    };

    SimpleSlider.addSpans = function(slider) {
        var headers = slider.find('.slide').find(':header:first');
        headers.append('<span></span>');
        headers.prepend('<span></span>');
    };

    SimpleSlider.initializeNavigation = function(slider) {
        var backButton = slider.find('.slide').find(':header:first span:first');
        var nextButton = slider.find('.slide').find(':header:first span:last');
        backButton.click(function() {
            SimpleSlider.goToPreviousSlide(slider);
        });
        nextButton.click(function() {
            SimpleSlider.goToNextSlide(slider);
        });
    };

    SimpleSlider.goToNextSlide = function(slider) {
        var visibleSlide = slider.find('.slide:visible');
        var isLastSlide = visibleSlide.is(slider.find('.slide:last'));
        var firstSlide = slider.find('.slide:first');
        var nextSlide = visibleSlide.next('.slide');
        visibleSlide.hide();
        if (isLastSlide) {
            firstSlide.show();
            return;
        }
        nextSlide.show();
    };

    SimpleSlider.goToPreviousSlide = function(slider) {
        var visibleSlide = slider.find('.slide:visible');
        var isFirstSlide = visibleSlide.is(slider.find('.slide:first'));
        var lastSlide = slider.find('.slide:last');
        var previousSlide = visibleSlide.prev('.slide');
        visibleSlide.hide();
        if (isFirstSlide) {
            lastSlide.show();
            return;
        }
        previousSlide.show();
    };

// SET RELATION ID COOKIE

    function RelationIdCookie() {

        var relationIdElement = $('#relationId');

        this.initialize = function() {

            var relationIdExists = relationIdElement.length;

            if (!relationIdExists) {
                return;
            }

            var cookieSettings = {
                name: "relation_id_cookie",
                value: relationIdElement.val(),
                duration: 3600 * 24 * 365
            };

            var relationIdCookie = new Cookie(cookieSettings);

            setCookie(relationIdCookie);
        };

        var setCookie = function(cookie) {
            if (cookie.getCookie() !== relationIdElement.val()) {
                cookie.setCookie();
            }
        };

    }

// FULL SCREEN BANNERS

    function FullScreenBanner() {

        var banners = $('.content-wrapper.full-screen');
        var navigationBarHeight = getNavigationHeight();
        var viewportHeight = window.innerHeight;

        this.initialize = function() {

            banners.each(function() {

                var banner = $(this);

                setHeight(banner);

            })

        };

        var setHeight = function(banner) {

            var fullScreenHeight = viewportHeight - navigationBarHeight;

            banner.css('height', fullScreenHeight + 'px');

        };

        function getNavigationHeight() {

            var navigation = $('#navigation-bar');
            var navigationSubmenuVisible = navigation.hasClass('submenu-visible');

            if (navigationSubmenuVisible) {

                var navigationSubmenu = navigation.find('.submenu-list:visible');

                return navigation.innerHeight() + navigationSubmenu.innerHeight();

            }

            if (navigation.length) {

                return -100;

            }

            return 0;

        }

    }

// GENERAL METHODS

    // Toggles opened and closed classes to show and hide dropdown items using css transitions.
    function cssToggle(elementId) {
        var element = $(elementId);
        if (element.hasClass('opened')) {
            element.removeClass('opened').addClass('closed');
        } else {
            element.removeClass('closed').addClass('opened');
        }
    }

    // When any other element then the elementId is clicked, close the component
    function clickAway(elementId, exceptionIdName) {
        $(document).on('click touchstart', function(event) {
            var elementExists = $(event.target).closest(elementId).length;
            var elementIsOpened = $(elementId).hasClass('opened');
            if (typeof exceptionIdName === 'undefined') {
                if (!elementExists && elementIsOpened) {
                    $(elementId).removeClass('opened').addClass('closed');
                }
            } else {
                var elementIsException = $(event.target).hasClass(exceptionIdName);
                if (!elementExists && !elementIsException && elementIsOpened) {
                    $(elementId).removeClass('opened').addClass('closed');
                }
            }
        });
    }

    // Enter a string to return a string with the spaces on the beginning and end of the string trimmed off.
    function trimSpaces(string) {
        return $.trim(string);
    }

    // SETTING AND GETTING COOKIES

        function Cookie(settingsObject) {

            var defaults = {
                name: "",
                value: 1,
                duration: 3600 * 24,
                domain: $("#cookie-domain").text(),
                path: "/"
            };

            var cookie = $.extend(defaults, settingsObject);

            if (cookie.name === "") {
                throw 'The "name" property on the settingsObject is not filled.';
            }

            this.setCookie = function() {
                var currentDate = new Date();
                var expirationDate = new Date();
                expirationDate.setTime(currentDate.getTime() + cookie.duration * 1000);
                document.cookie = cookie.name + "=" + cookie.value + ";path=" + cookie.path + ";domain="
                    + cookie.domain
                    + ";expires=" + expirationDate.toUTCString();
            };

            this.getCookie = function() {
                var name = cookie.name + "=";
                var ca = document.cookie.split(';');
                for(var i=0; i<ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0)==' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0){
                        return c.substring(name.length,c.length);
                    }
                }
                return null;
            };

        }

    // SORTING ARRAYS

        var SortArray = {};

        SortArray.ascending = function(array) {
            return array.sort(function(a, b) {
                return a-b;
            });
        };

        SortArray.descending = function(array) {
            return array.sort(function(a, b) {
                return b-a;
            });
        };

    // MANIPULATE KEY INPUT BEHAVIOUR

        var KeyCodes = function() {

            this.preventDefaultBehaviour = function(event, keycodes) {
                for (var i in keycodes) {
                    if (event.keyCode == keycodes[i]) {
                        event.preventDefault();
                    }
                }
            }

        };

    // STRING MANIPULATION

        var StringManipulation = {};

        StringManipulation.removeSpaces = function(string) {
            return string.replace(/ /g, '');
        };

    // INPUT VALUE EVALUATIONS

        var inputValueEvaluation = {};

        inputValueEvaluation.inputHasValue = function(inputElement, value) {
            return inputElement.val() == value;
        };

        inputValueEvaluation.inputIsEmpty = function(inputElement) {
            return inputElement.val() == "";
        };

        inputValueEvaluation.inputIsFilled = function(inputElement) {
            return inputElement.val() != "";
        };

    // SCREEN CHECKING

        function CheckScreen() {

            this.tablet = function() {
                var screenWidth = getScreenWidth();
                var maxWidth = 1024;
                var minWidth = 641;
                return screenWidth <= maxWidth && screenWidth >= minWidth;
            };

            this.mobile = function() {
                var screenWidth = getScreenWidth();
                var maxWidth = 640;
                return screenWidth <= maxWidth;
            };

            this.innerTablet = function() {
                var screenWidth = getInnerScreenWidth();
                var maxWidth = 1024;
                var minWidth = 641;
                return screenWidth <= maxWidth && screenWidth >= minWidth;
            };

            this.innerMobile = function() {
                var screenWidth = getInnerScreenWidth();
                var maxWidth = 640;
                return screenWidth <= maxWidth;
            };

            function getScreenWidth() {
                return $(window).width();
            }

            function getInnerScreenWidth() {
                return $(window).innerWidth();
            }
        }

    // ENVIRONMENT CHECKING

        function CheckEnvironment() {

            var scope = this;

            this.isLocal = function() {

                return $("body").hasClass("local");

            };

            this.isTest = function() {

                return $("body").hasClass("test");

            };

            this.isAcceptance = function() {

                return $("body").hasClass("acc");

            };

            this.isProduction = function() {

                return !scope.isTest() && !scope.isAcceptance();

            };

            this.isMyArea = function() {

                return $("#navigation-bar").hasClass("my-budget-page");

            };

        }

// SIMPLE POPUP

    function SimplePopup(parentElementSelector = '') {

        var titleClass = 'simple-popup-title';
        var backgroundClass = 'background';
        var closeClass = 'close';
        var dataTriggerAttribute = 'simple-popup-trigger';
        var dataContentAttribute = 'simple-popup-content';
        var duration = 200;

        this.initialize = function() {

            addPopupContent();
            openPopup();
            closePopup();

        };

        var addPopupContent = function () {

            var popup = $(`${parentElementSelector} *[data-${dataContentAttribute}]`);

            popup.prepend('<span class="' + backgroundClass + ' ' + closeClass + '"></span>');

            popup.find('.' + titleClass).append('<span class="' + closeClass + '"></span>');

        };

        var openPopup = function() {

            $(`${parentElementSelector} *[data-${dataTriggerAttribute}]`).on('click', function() {

                var dataValue = $(this).data(dataTriggerAttribute);
                var popup = $('*[data-' + dataContentAttribute +'=' + dataValue + ']');

                popup.fadeIn(duration);

            });

        };

        var closePopup =  function() {

            $(`${parentElementSelector} *[data-${dataContentAttribute}] .close`).on('click', function() {

                var popup = $(this).closest('[data-' + dataContentAttribute + ']');

                popup.fadeOut(duration);

            });

        };

    }